import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder($input: OrderInput!) {
    createOrder(input: $input) {
      id
      createdAt
      date
      number
      totalAmount
      state
      uploads
      tasks {
        id
        type
        amount
        state
        documents {
          id
          name
          path
        }
      }
      documents {
        id
        name
        path
      }
    }
  }
`;

export const READ_ORDER = gql`
  mutation readOrder($id: ID!) {
    readOrder(id: $id)
  }
`;

export const PAY_ORDER = gql`
  mutation payOrder($id: ID!, $purpose: TransactionPurpose!) {
    payOrder(id: $id, purpose: $purpose) {
      order {
        id
        createdAt
        date
        number
        totalAmount
        state
        uploads
        tasks {
          id
          type
          amount
          state
          documents {
            id
            name
            path
          }
        }
        documents {
          id
          name
          path
        }
      }
      transaction {
        id
        token
        invoice
        image
        description
        entry
        amount
        state
        purpose
        object
        links {
          name
          logo
          description
          link
        }
      }
    }
  }
`;
