import React, { ReactNode, useEffect } from 'react';
import { useCallStore } from 'context/call.store';
import { getPayload } from './auth';
import { IOrder } from 'types';
import { useQuery, useSubscription } from '@apollo/client';
import { ON_UPDATED_ORDER } from 'graphql/subscription/order';
import { GET_ORDERS } from 'graphql/query';

interface SubscriptionProviderProps {
  children: ReactNode;
}

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const { authenticated } = useCallStore();
  const userId = authenticated ? getPayload()?.sub : undefined;

  const { data: ordersData, loading: ordersLoading } = useQuery(GET_ORDERS, {
    skip: !userId,
  });

  useSubscription(ON_UPDATED_ORDER, {
    variables: {},
    skip: !userId,
    onData: ({ client, data }) => {
      if (!data) return;
      const caches = client.readQuery<{ getOrders: IOrder[] }>({ query: GET_ORDERS });
      if (!caches?.getOrders) return;

      const { event, order: subscriptionOrder } = data.data.onUpdatedOrder;
      let updatedOrders = caches.getOrders.map((order) =>
        order.id === subscriptionOrder.id ? subscriptionOrder : order,
      );
      switch (event) {
        case 'CREATED':
        case 'UPDATED':
          updatedOrders = updatedOrders.filter((order) => order.id !== subscriptionOrder.id);
          updatedOrders.unshift(subscriptionOrder);
          break;
        case 'DELETE':
          updatedOrders = updatedOrders.filter((order) => order.id !== subscriptionOrder.id);
          break;
        default:
          return;
      }

      client.writeQuery({
        query: GET_ORDERS,
        data: { getOrders: updatedOrders },
      });
    },
  });

  useEffect(() => {
    if (ordersLoading) return;
  }, [ordersLoading, ordersData]);

  return <>{children}</>;
};

export default SubscriptionProvider;
