import { QPAY_BANK_TYPE } from 'constants/bank';

export const qpyBanks = [
  {
    type: QPAY_BANK_TYPE.MONPAY,
  },
  {
    type: QPAY_BANK_TYPE.SOCIAL_PAY,
  },
  {
    type: QPAY_BANK_TYPE.ARIG_BANK,
  },
  {
    type: QPAY_BANK_TYPE.MBANK,
  },
  {
    type: QPAY_BANK_TYPE.KHAAN_BANK,
  },
  {
    type: QPAY_BANK_TYPE.BOGD_BANK,
  },
  {
    type: QPAY_BANK_TYPE.CAPITRON_BANK,
  },
  {
    type: QPAY_BANK_TYPE.CHINGIG_KHAAN_BANK,
  },
  {
    type: QPAY_BANK_TYPE.MOST_MONEY,
  },

  {
    type: QPAY_BANK_TYPE.STATE_BANK,
  },
  {
    type: QPAY_BANK_TYPE.TRADE_AND_DEVELOPMENT_BANK,
  },
  {
    type: QPAY_BANK_TYPE.KHAS_BANK,
  },
];

export const purposeWorks = [
  {
    name: 'Ахуйн хэрэглээ',
    value: '1001',
  },
  {
    name: 'Үйлдвэр, үйлчилгээ барилгийн гадна цахилгаан хангамж',
    value: '1002',
  },
  {
    name: 'Орон сууцны барилгын гадна цахилгаан хангамж',
    value: '1003',
  },
  {
    name: 'Цэцэрлэгийн гадна цахилгаан хангамж',
    value: '1004',
  },
  {
    name: 'Эмнэлгийн гадна цахилгаан хангамж',
    value: '1005',
  },
  {
    name: 'Шугам, дэд станц зөөх',
    value: '1006',
  },
  {
    name: 'Нийтийн эзэмшлийн гэрэлтүүлэг',
    value: '1007',
  },
  {
    name: 'Барилгын түр тэжээл',
    value: '1008',
  },
  {
    name: 'Бусад',
    value: '1009',
  },
];

export const workTypes = [
  {
    name: '0.4кВ-ын агаарын шугам',
    value: '2001',
  },
  {
    name: '0.4кВ-ын кабель шугам',
    value: '2002',
  },
  {
    name: '6-10/0.4кВ-ын дэд өртөө ХТП',
    value: '2003',
  },
  {
    name: '6-10/0.4кВ-ын иж бүрдмэл дэд өртөө КТПН',
    value: '2004',
  },
  {
    name: '35/04кВ-ын дэд өртөө ',
    value: '2005',
  },
  {
    name: '35/10кВ-ын дэд станц ',
    value: '2006',
  },
  {
    name: 'Хуваарилах байгууламж РП',
    value: '2007',
  },
];

export const abilities = [
  {
    name: '10кВА',
    value: '3001',
  },

  {
    name: '460кВА',
    value: '3002',
  },

  {
    name: '80кВА',
    value: '3003',
  },

  {
    name: '20кВА',
    value: '3004',
  },
];

export const publicationTypes = [
  {
    label: 'Цахилгаан угсралт',
    value: 'electricalInstallation',
  },
  {
    label: 'Техникийн хяналт',
    value: 'technicalControl',
  },
  {
    label: 'Ажлын зураг',
    value: 'workingDraw',
  },
];
