import ErrorPage from 'pages/error/notFound';
import Order from './order';
import QuantityWork from './quantityWork';
import Transactions from './transactions';
import Dashboard from './dashboard';

export const dashboardRoutes = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/order',
    component: <Order />,
  },
  {
    path: '/work',
    component: <QuantityWork />,
  },
  {
    path: '/transactions',
    component: <Transactions />,
  },
];
