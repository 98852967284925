import { useQuery } from '@apollo/client';
import { GET_ADMIN_OFFERS, GET_ORDERS } from 'graphql/query';
import { purposeWorks, workTypes } from 'mocks';
import DataTable, { TableStyles } from 'react-data-table-component';
import { AiOutlineInbox, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { downloadFile, getPurposeWork, orderStatusColor } from 'tools';
import { IDocument } from 'types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatWithCommas } from 'utils';


const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        height: '60px',
      },
    },
  },
};

const Index = () => {
  const { t } = useTranslation('language');
  const { data, loading } = useQuery(GET_ADMIN_OFFERS);

  const { data: order } = useQuery(GET_ORDERS);

  const columns: any = [
    {
      name: 'Үүссэн огноо',
      selector: (row: any) => moment(row.createdAt).format('YYYY-MM-DD'),
      wrap: true,
      sortable: true,
      center: false,
    },
    {
      name: 'Байгууллагын нэр',
      selector: (row: any) => row.partner.name,
      wrap: true,
      sortable: true,
      center: true,
      width: '170px',
    },
    {
      name: 'Байгууллагын дугаар',
      selector: (row: any) => row.partner.phone,
      wrap: true,
      sortable: true,
      center: true,
      width: '170px',
    },
    {
      name: 'Захиалагчийн нэр',
      selector: (row: any) => {
        let targetOrder = order?.getOrders?.find((order: any) => order.id === row.task.orderId);
        return targetOrder?.user.firstName;
      },
      wrap: true,
      sortable: true,
      center: true,
      width: '170px',
    },
    {
      name: 'Захиалагчийн утасны дугаар',
      selector: (row: any) => {
        let targetOrder = order?.getOrders?.find((order: any) => order.id === row.task.orderId);
        return targetOrder.user.phone;
      },
      wrap: true,
      sortable: true,
      center: true,
      width: '150px',
    },
    {
      name: 'Ажлын Төрөл',
      selector: (row: any) => {
        const config = row.task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'workType:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {workTypes.find((work: { name: string; value: string }) =>
                work.value.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      width: '150px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын зориулалт',
      selector: (row: any) => {
        const config = row.task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'purposeWork:') : null;

        if (!purposeWork) return '';

        const purposeWorkValue = purposeWork.split(':')[1];

        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {purposeWorks.find((work: { name: string; value: string }) =>
                work.value.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: 'Ажлын тоо хэмжээ',
      selector: (row: any) => {
        const adminDocument = row?.task.documents?.find((doc: IDocument) => doc.input === 'admin');

        const isAdminDocumentPresent = adminDocument !== undefined;

        return (
          <span
            style={{ textWrap: 'balance' }}
            className="flex gap-2 text-wrap cursor-pointer font-normal place-content-center"
          >
            {isAdminDocumentPresent ? (
              <span
                onClick={() => downloadFile(adminDocument.path)}
                className="text-xs text-gray-800"
              >
                Татах
              </span>
            ) : (
              <span className="text-xs text-gray-800">Хүлээгдэж байгаа</span>
            )}

            {isAdminDocumentPresent && (
              <FiDownload
                onClick={() => downloadFile(adminDocument.path)}
                className="text-gray-600 text-lg"
              />
            )}
          </span>
        );
      },
      wrap: true,
      width: '150px',
      sortable: true,
      center: true,
    },
    {
      name: 'Үнийн дүн',
      selector: (row: any) => <span className="font-normal">{formatWithCommas(row.price)} ₮</span>,
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Шимтгэл',
      selector: (row: any) => (
        <span className="font-normal">{formatWithCommas(row.chargeAmount)} ₮</span>
      ),
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Төлөв',
      selector: (row: any) => (
        <span
          className={`badge text-wrap cursor-pointer font-normal		 ${orderStatusColor(
            row.state,
          )} place-content-center`}
        >
          {t(`${row.state}`)}
        </span>
      ),
      wrap: true,
      width: '150px',
      sortable: true,
      center: true,
    },
  ];

  return (
    <div className="block overflow-hidden">
      <DataTable
        columns={columns}
        data={data?.getAdminOffers ?? []}
        customStyles={customStyles}
        highlightOnHover
        noDataComponent={
          <div className="grid place-items-center	">
            <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
            <span className="text-gray-500 text-sm ">Хоосон байна</span>
          </div>
        }
        pagination
        responsive
        progressComponent={
          <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
        }
      />
    </div>
  );
};

export default Index;
