import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { publicRoutes } from './routes';

const Public: React.FC = () => {
  return (
    <Routes>
      {publicRoutes.map((option) => (
        <Route key={option.path} path={option.path} element={option.element} />
      ))}
    </Routes>
  );
};

export default Public;
