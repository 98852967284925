import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  TooltipProps,
} from 'recharts';
import { formatWithCommas } from 'utils';

type Props = {
  data: any[];
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length && payload[0].value !== undefined) {
    return (
      <div className="custom-tooltip">
        <p className="label">{` ${label} `}</p>
        <p className="intro">{`Нийт дүн: ₮ ${formatWithCommas(payload[0].value as number)}`}</p>
      </div>
    );
  }

  return null;
};

const Chart = ({ data }: Props) => {
  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3674d9" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3674d9" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Area
            dot={true}
            activeDot
            strokeWidth={2}
            type="monotone"
            dataKey="amount"
            stroke="#3674d9"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
