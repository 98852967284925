import { ITransaction } from 'types';

export function formatWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function extractBase64Content(base64Data: string) {
  const matches = base64Data.match(/^data:(.*?);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    throw new Error('Invalid base64 string');
  }

  const contentType = matches[1];
  const base64Content = matches[2];

  return { contentType, base64Content };
}

export async function uploadToS3(presignedUrl: string, base64Data: any, fileData: any) {
  try {
    const { contentType, base64Content } = extractBase64Content(base64Data);

    const fileBuffer = Buffer.from(base64Content, 'base64');

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: fileBuffer,
    };
    const response = await fetch(presignedUrl, requestOptions);
    if (response.ok) {
      console.log('File uploaded successfully');
    } else {
      console.error('File upload failed:', response.statusText);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

export function base64ToBlob(base64: string, contentType: string): Blob {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export const ConvertImgUrl = (transaction: ITransaction) => {
  return `data:image/jpeg;base64,${transaction.image} `;
};

export const multiSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: 'rgb(var(--gray-6)/var(--tw-border-opacity))',
    borderWidth: 2,
    borderRadius: 10,
    padding: '2px 2px',
    focusColor: 'rgb(var(--gray-6)/var(--tw-border-opacity))',
    hoverColor: 'rrgb(var(--gray-6)/var(--tw-border-opacity))',
    '&:hover': {
      borderColor: 'rgb(var(--gray-6)/var(--tw-border-opacity))',
    },
    fontSize: '12px',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(255,255,255, 0.2)' : 'none',
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: 'black',
    fontSize: '12px',
  }),
  singleValue: (baseStyles: any) => ({
    ...baseStyles,
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '12px',
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    backgroundColor: '#E5E5E5',
    color: 'rgba(0, 0, 0, 0.8)',
  }),
  multiValueLabel: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.8)',
  }),
  multiValueRemove: (baseStyles: any) => ({
    ...baseStyles,
    color: 'rgba(0, 0, 0, 0.5)',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      color: 'rgba(0, 0, 0, 0.8)',
    },
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: '12px',
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.8)',
  }),
};
