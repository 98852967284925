import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { orderStatusColor, paymentStateColor } from 'tools/convertColors';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ORDERS, GET_ORDER_TRANSACTION } from 'graphql/query';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { AiOutlineInbox } from 'react-icons/ai';
import { useEffect, useRef, useState } from 'react';
import { IDocument, ITask, ITransaction } from 'types';
import { FiEye } from 'react-icons/fi';
import { MdOutlineFileDownload } from 'react-icons/md';
import Button from 'components/Button';
import ControlledAttachFile from 'components/ControlledForms/ControlledAttachFile';
import { FieldValues, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { IoMdAttach } from 'react-icons/io';
import { READ_ORDER } from 'graphql/mutation';
import { ATTACH_WORK, DELETE_DOCUMENT } from 'graphql/mutation/task';
import { multiSelectStyles, uploadToS3 } from 'utils';
import { useNotifications } from 'providers';
import { FiTrash2 } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import { TaskState, TECHNICAL_CONDITIONS } from 'constants/type';
import { FiDownload } from 'react-icons/fi';
import Select from 'react-select';
import { publicationTypes, purposeWorks, workTypes } from 'mocks';
import { extractName, downloadFile, getPurposeWork } from 'tools';
import ControlledInput from 'components/ControlledForms/ControlledInput';

const Index = () => {
  const { t } = useTranslation('language');
  const ref = useRef<any>(null);
  const { addNotification } = useNotifications();
  const [selectedRow, setSelectedRow] = useState<any>();
  const { data, loading, refetch } = useQuery(GET_ORDERS);

  useEffect(() => {
    refetch();
  }, []);

  const [attaching, setAttaching] = useState(false);

  const [attachWork] = useMutation(ATTACH_WORK, {
    onCompleted: async (data) => {
      if (!isEmpty(files)) {
        for (const file of files) {
          const uploadUrl = data.attachWork.uploads.find((url: string) => {
            const normalizedUrl = decodeURIComponent(url)
              .toLowerCase()
              .replace(/[\s_]+/g, '');
            const normalizedFileName = file.name.toLowerCase().replace(/[\s_]+/g, '');
            return normalizedUrl.includes(normalizedFileName);
          });

          if (uploadUrl) {
            try {
              await uploadToS3(uploadUrl, file.content, file);
            } catch (uploadError) {
              addNotification(`Failed to upload ${file.name}`, 'error');
              console.error(`Error uploading file ${file.name}:`, uploadError);
            }
          } else {
            addNotification(`Upload URL not found for ${file.name}`, 'error');
          }
        }
      }
      setAttaching(false);
      refetch();
      handleClose();
      addNotification('Ажлын тоо хэмжээ амжилттай илгээгдлээ', 'success');
    },
    onError: (error: any) => {
      addNotification(error.message, 'error');
      setAttaching(false);
    },
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: (data) => {
      if (data.deleteDocument) {
        refetch();
        handleClose();
        addNotification('Амжилттай', 'success');
      }
    },
  });

  const [readOrder] = useMutation(READ_ORDER, {
    onCompleted: (data) => {
      if (data.readOrder) refetch();
    },
  });

  const { control, handleSubmit, watch, setValue, reset } = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      files: [],
      detailedPicture: false,
      publicationType: [],
    },
  });

  const { files, task, detailedPicture, purposeWork, workType, publicationType, ability } = watch();

  const onSubmit = (data: FieldValues) => {
    if (!isEmpty(files) && !isEmpty(selectedRow) && task) {
      let taskConfig = JSON.stringify(
        [
          purposeWork ? `purposeWork:${purposeWork}` : undefined,
          workType ? `workType:${workType}` : undefined,
          ability ? `ability:${ability}кВА` : undefined,
        ].filter((item) => item !== undefined),
      );

      const input = {
        input: 'ADMIN',
        uploads: files.map((file: any) => file.name),
        detailedPicture: detailedPicture,
        taskConfig: taskConfig,
        workTypes: publicationType?.map((item: any) => item.value),
      };
      setAttaching(true);
      attachWork({ variables: { id: task.id, input: input } });
    }
  };

  const deleteDoc = (id: string) => {
    deleteDocument({ variables: { id: id } });
  };

  const [getOrderTransaction, { data: transactions, loading: transactionLoading }] =
    useLazyQuery(GET_ORDER_TRANSACTION);

  const columns: any = [
    {
      name: 'Үүссэн огноо',
      selector: (row: any) => row.date,
      sortable: true,
      center: false,
      width: '150px',
    },
    {
      name: 'Утас',
      selector: (row: any) => row.user.phone,
      sortable: true,
      center: false,
    },
    {
      name: 'Хэрэглэгчийн нэр',
      selector: (row: any) => row.user.firstName,
      sortable: true,
      center: true,
      width: '180px',
    },
    {
      name: 'Ажлын зориулалт',

      selector: (row: any) => {
        const task = row.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);

        if (!task) return '';

        const config = task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'purposeWork:') : null;

        if (!purposeWork) return '';

        const purposeWorkValue = purposeWork.split(':')[1];

        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {purposeWorks.find((work: { name: string; value: string }) =>
                work.value.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      width: '200px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын Төрөл',
      selector: (row: any) => {
        const task = row.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);

        if (!task) return '';

        const config = task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'workType:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {workTypes.find((work: { name: string; value: string }) =>
                work.value.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      width: '150px',
      sortable: true,
      center: false,
    },
    {
      name: 'Чадал',
      selector: (row: any) => {
        const task = row.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);

        if (!task) return '';

        const config = task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'ability:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return purposeWorkValue || '';
      },
      wrap: true,
      sortable: true,
      center: false,
    },
    {
      name: 'Техникийн нөхцөл',
      selector: (row: any) => {
        const task = row.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);
        const isTechnical = !task;
        const userDocument = task?.documents?.find((doc: IDocument) => doc.input === 'user');

        return (
          <span
            style={{ textWrap: 'balance' }}
            className="flex gap-1 cursor-pointer text-wrap font-normal place-content-center"
          >
            {isTechnical ? (
              <span className="text-xs text-gray-800">Хоосон</span>
            ) : (
              <>
                <span
                  onClick={() => userDocument && window.open(userDocument.path)}
                  className="text-xs text-gray-800"
                >
                  Харах
                </span>
                <IoEyeOutline
                  onClick={() => userDocument && window.open(userDocument.path)}
                  className="text-gray-600 text-lg"
                />
              </>
            )}
          </span>
        );
      },
      wrap: true,
      width: '170px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын тоо хэмжээ',
      selector: (row: any) => {
        const adminDocument = row.tasks
          ?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS)
          ?.documents?.find((doc: IDocument) => doc.input === 'admin');

        const isAdminDocumentPresent = adminDocument !== undefined;

        return (
          <span
            style={{ textWrap: 'balance' }}
            className="flex gap-2 text-wrap cursor-pointer font-normal place-content-center"
          >
            {isAdminDocumentPresent ? (
              <span
                onClick={() => downloadFile(adminDocument.path)}
                className="text-xs text-gray-800"
              >
                Татах
              </span>
            ) : (
              <span className="text-xs text-gray-800">Хүлээгдэж байгаа</span>
            )}

            {isAdminDocumentPresent && (
              <FiDownload
                onClick={() => downloadFile(adminDocument.path)}
                className="text-gray-600 text-lg"
              />
            )}
          </span>
        );
      },
      width: '170px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Төлөв',
      selector: (row: any) => {
        const task = row.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);
        return (
          <>
            <span
              id="not-clickable"
              style={{ textWrap: 'balance' }}
              className={`badge text-wrap font-normal cursor-pointer	leading-4	 ${orderStatusColor(
                row.state,
              )} place-content-center`}
            >
              {t(`${task.state}`)}
            </span>
          </>
        );
      },
      sortFunction: (rowA: any, rowB: any) => {
        const taskA = rowA.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);
        const taskB = rowB.tasks?.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);

        if (!taskA || !taskB) return 0;

        const sortOrder = {
          [TaskState.DRAFT]: 1,
          [TaskState.PENDING]: 2,
          [TaskState.ONGOING]: 3,
          [TaskState.POSTED]: 4,
          [TaskState.COMPLETED]: 5,
          [TaskState.CANCELLED]: 6,
        };

        const stateA = taskA.state;
        const stateB = taskB.state;

        return (sortOrder[stateA] || 999) - (sortOrder[stateB] || 999);
      },

      wrap: true,
      width: '170px',
      sortable: true,
      center: true,
    },
  ];

  const handleClose = () => {
    if (ref.current) {
      ref.current.checked = false;
      setValue('files', []);
      setValue('detailedPicture', false);
    }
  };

  const onRowClicked = (value: any) => {
    if (!value) return;
    setSelectedRow(value);
    reset();
    if (!value.isRead) readOrder({ variables: { id: value.id } });
    getOrderTransaction({ variables: { id: value.id } });
  };

  const renderTransaction = (transaction: ITransaction) => (
    <>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Огноо:</span>
        <span className="font-semibold">{transaction.payedAt}</span>
      </div>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Дүн:</span>
        <span className="font-semibold"> ₮ {transaction.amount}</span>
      </div>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Төлбөрийн төлөв:</span>
        <span
          className={` badge text-xs font-normal	p-1 px-2 cursor-pointer	 ${paymentStateColor(
            transaction.state,
          )} `}
        >
          {t(`${transaction.state}`)}
        </span>
      </div>
    </>
  );

  const renderDocument = (document: IDocument, index: number) => (
    <div
      key={index}
      className={`flex  p-2  border border-gray-300 rounded-lg place-items-center   justify-between  mb-2`}
    >
      <div className="flex place-items-center gap-2">
        <IoMdAttach className="text-gray-500" />
      </div>
      <p className="text-sm mx-2 font-normal w-full truncate text-ellipsis overflow-hidden ">
        {document.name}
      </p>
      <div className="flex gap-2">
        <FiEye
          className=" cursor-pointer text-lg text-gray-500"
          onClick={() => window.open(document.path)}
        />
        <MdOutlineFileDownload
          className=" cursor-pointer text-lg text-gray-500"
          onClick={() => downloadFile(document.path)}
        />
        <FiTrash2
          className=" cursor-pointer text-lg text-red-500"
          onClick={() => deleteDoc(document.id)}
        />
      </div>
    </div>
  );

  const renderConfig = (config: any) => {
    let parsed = JSON.parse(config);
    if (!parsed) return null;
    return (
      <div className="grid w-full  text-xs mt-2 text-gray-500 items-center">
        {parsed?.map((item: string, index: number) => {
          const [key, value] = item.split(':');
          return (
            <div key={index} className="mb-2 gap-4 flex w-full justify-between">
              <span className="text-xs text-gray-500">{t(key)}:</span>
              <div className="font-semibold text-end">{extractName(value)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTask = (task: ITask) => (
    <>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Ажлын нэр төрөл:</span>
        <span className="font-semibold">{t(`${task.type}`)}</span>
      </div>
      {renderConfig(task.taskConfig)}
      <div className="flex place-content-between text-xs  text-gray-500 place-items-center">
        <span>Төлөв:</span>
        <span
          className={` badge text-xs font-normal	p-1 px-2 cursor-pointer ${orderStatusColor(
            task.state,
          )} `}
        >
          {t(`${task.state}`)}
        </span>
      </div>

      <div className="mb-2">
        <span className="text-xs text-gray-500">Техникийн нөхцөл:</span>
      </div>
      {task.documents
        .filter((d) => d.input === 'user')
        ?.map((doc: IDocument, index) => renderDocument(doc, index))}
      {!isEmpty(task.documents.filter((d) => d.input === 'admin')) && (
        <div className="mb-2">
          <span className="text-xs text-gray-500">Ажлын тоо хэмжээ:</span>
        </div>
      )}
      {task.documents
        .filter((d) => d.input === 'admin')
        ?.map((doc: IDocument, index) => renderDocument(doc, index))}
      {task.state !== 'POSTED' && task.state !== 'COMPLETED' ? (
        <>
          <div className="mt-3" onClick={() => setValue('task', task)}>
            <ControlledAttachFile
              control={control}
              name="files"
              buttonText="Ажлын тоо хэмжээ хавсаргах"
            />
          </div>
          <div
            className="flex gap-2 cursor-pointer mt-2 place-items-center "
            onClick={() => setValue('detailedPicture', !detailedPicture)}
          >
            <div
              className=" flex p-2 gap-2 mb-2 text-blue-800 w-full self-center rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
              role="alert"
            >
              <input
                type="checkbox"
                className="checkbox-solid bg-gray-300 focus:bg-gray-400 checkbox"
                checked={detailedPicture}
              />
              <span className="text-xs  self-center content-center">
                Нарийвчилсан зураг шаардлагатай эсэх
              </span>
            </div>
          </div>
          <div className="w-full pb-2">
            <div className="mb-1">
              <span className="text-gray-800 text-xs font-semibold ">Гүйцэтгэгч сонгох</span>
            </div>
            <Select
              isMulti
              styles={multiSelectStyles}
              name="colors"
              options={publicationTypes}
              className=""
              placeholder="Сонгох"
              classNamePrefix="Сонгох"
              onChange={(e) => setValue('publicationType', e)}
            />
          </div>

          <div className="w-full pb-2">
            <div className="mb-1">
              <span className="text-gray-800 text-xs font-semibold ">Ажлын зориулалт</span>
            </div>
            <select
              className="select select-block text-xs"
              defaultValue={purposeWork}
              value={purposeWork || ''}
              onChange={(e) => setValue('purposeWork', e.target.value)}
            >
              <option value="" disabled selected hidden>
                Сонгох
              </option>
              {purposeWorks.map((purpose: any) => (
                <option value={purpose.value} className="text-gray-800 text-xs">
                  {purpose.name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full pb-2">
            <div className="mb-1">
              <span className="text-gray-800 text-xs font-semibold ">Ажлын төрөл</span>
            </div>
            <select
              className="select select-block text-xs"
              defaultValue={workType}
              value={workType || ''}
              onChange={(e) => setValue('workType', e.target.value)}
            >
              <option value="" disabled selected hidden>
                Сонгох
              </option>
              {workTypes.map((workType) => (
                <option className="text-gray-800 text-xs" value={workType.value}>
                  {workType.name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full pb-2 ">
            <div className="mb-1">
              <span className="text-gray-800 text-xs font-semibold ">Чадал</span>
            </div>
            <div className="suffix-input">
              <ControlledInput type="number" control={control} name="ability" id="ability" />
            </div>
          </div>

          <Button
            text="Илгээх"
            isSolid
            loading={attaching}
            disabled={isEmpty(files)}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      ) : (
        <div className="flex  gap-2 cursor-pointer w-full mb-2 mt-2">
          <div
            className={`p-2  flex ${
              task.detailedPicture ? ' bg-yellow-50 text-yellow-800' : ' bg-blue-50 text-blue-800 '
            } w-full rounded-lg  `}
            role="alert"
          >
            <span className="text-xs ml-1 self-center content-center ">
              Нарийвчилсан зураг {task.detailedPicture ? 'шаардлагатай' : 'шаардлагагүй'}
            </span>
          </div>
        </div>
      )}
    </>
  );

  const renderContent = () => (
    <>
      <div className="overflow-y-scroll	min-h-full mb-4	">
        <div className="p-4 pt-2 border border-gray-300 rounded-lg mt-3">
          <span className="text-sm font-semibold ">Захиалгын мэдээлэл</span>
          {transactionLoading && (
            <div className="grid place-items-center  ">
              <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
            </div>
          )}
          {transactions &&
            transactions.getOrderTransaction.map((transaction: ITransaction) =>
              renderTransaction(transaction),
            )}
        </div>
        <div className="pt-3 pl-2 ">
          <span className="text-sm  font-semibold">Ажлууд</span>
        </div>

        <div className="p-4 pt-2 border border-gray-300 rounded-lg mt-3">
          {selectedRow && selectedRow?.tasks?.map((task: ITask) => renderTask(task))}
        </div>
      </div>
    </>
  );

  const conditionalRowStyles = [
    {
      when: (row: any) => !row.isRead,
      style: {
        backgroundColor: 'rgb(94 94 94 / 10%)',
        color: 'black',
      },
    },
  ];

  const onModal = (e: any) => {
    if (!e.target.checked) {
      setSelectedRow(null);
      reset();
    }
  };

  const onDraw = (e: any) => {
    if (!e.target.checked) {
      setSelectedRow(null);
      reset();
    }
  };

  return (
    <>
      <label
        className="block overflow-hidden"
        htmlFor={`${window.innerWidth < 1024 ? 'drawer-bottom' : 'modal-1'}`}
      >
        <DataTable
          columns={columns}
          data={data ? data?.getOrders ?? [] : []}
          progressPending={loading}
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={onRowClicked}
          pagination
          noDataComponent={
            <div className="grid place-items-center	">
              <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
              <span className="text-gray-500 text-sm ">Хоосон байна</span>
            </div>
          }
          progressComponent={
            <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
          }
          highlightOnHover
          responsive
        />
      </label>
      {selectedRow && (
        <>
          <input
            ref={ref}
            type="checkbox"
            onChange={onDraw}
            id="drawer-bottom"
            className="drawer-toggle"
          />
          <label className="overlay" htmlFor="drawer-bottom"></label>
          <div className="drawer drawer-bottom h-full  ">
            <div className="drawer-content">
              <div className="flex place-items-center place-content-between">
                <p className="text-gray-800 font-semibold ">Захиалгын дэлгэрэнгүй</p>
                <label htmlFor="drawer-bottom" className="btn btn-sm btn-circle btn-ghost  ">
                  ✕
                </label>
              </div>
              <div className="font-semibold text-lg">
                # <span>{selectedRow?.number}</span>
              </div>
              {renderContent()}
            </div>
          </div>

          <input
            ref={ref}
            onChange={onModal}
            className="modal-state"
            id="modal-1"
            type="checkbox"
          />
          <div className="modal">
            <label className="modal-overlay" htmlFor="modal-1"></label>
            <div className="modal-content flex flex-col  w-5/12 p-8 pt-2 ">
              <label
                htmlFor="modal-1"
                className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
              >
                ✕
              </label>
              <div className="flex gap-2 mt-2 ">
                <span className="text-lg">Захиалгын дэлгэрэнгүй</span>
              </div>
              {renderContent()}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
