import { gql } from '@apollo/client';

export const REGISTER_PASSWORD = gql`
  mutation registerPassword($token: String!, $password: String!) {
    registerPassword(token: $token, password: $password) {
      token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($token: String!, $password: String!) {
    forgotPassword(token: $token, password: $password) {
      token
    }
  }
`;

export const LOGIN_BY_ADMIN = gql`
  mutation loginByAdmin($email: String!, $password: String!) {
    loginByAdmin(email: $email, password: $password) {
      token
    }
  }
`;
