import { gql } from '@apollo/client';

export const ATTACH_WORK = gql`
  mutation attachWork($id: ID!, $input: attachInput!) {
    attachWork(id: $id, input: $input) {
      id
      type
      amount
      state
      uploads
      documents {
        id
        name
        path
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;
