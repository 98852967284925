import React, { useContext } from 'react';
import Public from 'pages/public';
import { AuthContext } from 'providers';
import Dashboard from 'pages/private/dashboard';

const RoutesWrapper: React.FC = () => {
  const { authenticated } = useContext(AuthContext);
  // if (authenticated === null) return null;

  const routesToRender = authenticated ? <Dashboard /> : <Public />;

  return <>{routesToRender}</>;
};

export default RoutesWrapper;
