export const orderStatusColor = (status: string) => {
  switch (status) {
    case 'COMPLETED':
      return ' bg-green-200 border-green-200 text-green-500 ';
    case 'PENDING':
      return ' bg-blue-200  border-blue-200 text-blue-500';
    case 'CANCELLED':
      return 'bg-red-200  border-red-200 text-red-500 ';
    case 'ACCEPTED':
      return 'bg-yellow-200  border-yellow-200 text-yellow-500';
    case 'POSTED':
      return 'bg-yellow-200  border-yellow-200 text-yellow-500';
    case 'DRAFT':
      return 'bg-yellow-200  border-yellow-200 text-yellow-500';
    default:
      return 'badge-flat-error';
  }
};

export const paymentStateColor = (status: string) => {
  switch (status) {
    case 'PAID':
      return 'bg-green-200 border-green-200 text-green-500';
    case 'PENDING':
      return 'bg-blue-200  border-blue-200 text-blue-500';
    case 'CANCELLED':
      return 'bg-red-200  border-red-200 text-red-500';
    default:
      return 'bg-red-200  border-red-200 text-red-500';
  }
};

export const isDrawingColor = (status: boolean) => {
  if (status) {
    return 'badge-flat-warning';
  } else {
    return 'badge-flat-primary';
  }
};
