import { useLazyQuery } from '@apollo/client';
import { GET_TRANSACTIONS } from 'graphql/query';
import DataTable, { TableStyles } from 'react-data-table-component';
import { AiOutlineInbox, AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { formatWithCommas } from 'utils';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { mn } from 'date-fns/locale'; // Import the Mongolian locale
import { debounce } from 'lodash';
import { paymentStateColor } from 'tools';

const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        height: '60px',
      },
    },
  },
};

const Index = () => {
  const { t } = useTranslation('language');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [getTransaction, { data, loading }] = useLazyQuery(GET_TRANSACTIONS);

  const fetchTransactions = useCallback(
    debounce((formattedStartDate: string, formattedEndDate: string) => {
      getTransaction({
        variables: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      });
    }, 300), // Debounce delay of 300ms
    [],
  );

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

      fetchTransactions(formattedStartDate, formattedEndDate);
    }
  }, [startDate, endDate, fetchTransactions]);

  const columns: any = [
    {
      name: 'Гүйлгээ хийгдсэн огноо',
      selector: (row: any) => moment(row.payedAt).format('YYYY-MM-DD'),
      wrap: true,
      sortable: true,
      center: false,
    },
    {
      name: 'Үйлчилгээний Төрөл',
      selector: (row: any) => t(`transactionPurpose.${row.purpose}`),
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Хэрэглэгчийн Төрөл',
      selector: (row: any) => t(`userType.${row.purpose}`),
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Хэрэглэгчийн нэр',
      selector: (row: any) => (row.purpose === 'T' ? row.user.firstName : row.partner.name),
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Гүйлгээний дүн',
      selector: (row: any) => formatWithCommas(row.amount),
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Гүйлгээний төлөв',
      selector: (row: any) => (
        <span
          id="not-clickable"
          style={{ textWrap: 'balance' }}
          className={`badge text-wrap font-normal cursor-pointer	leading-4	 ${paymentStateColor(
            row.state,
          )} place-content-center`}
        >
          {t(`${row.state}`)}
        </span>
      ),
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Гүйлгээний төрөл',
      selector: (row: any) => t(row.entry),
      wrap: true,
      sortable: true,
      center: true,
    },
  ];

  const ExampleCustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        {value || 'Select Date'}
      </button>
    ),
  );

  ExampleCustomInput.displayName = 'ExampleCustomInput';

  const handleStartDateChange = (date: Date | null) => {
    let target = moment(date).format('YYYY-MM-DD');

    console.log('handleStartDateChange', target);
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <div className="block overflow-hidden p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4">
          <div>
            <label className="block text-[#164789] text-sm">Эхлэх огноо</label>
            <ReactDatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="yyyy-MM-dd"
              locale={mn}
              customInput={
                <ExampleCustomInput className=" px-2 text-sm mt-1 block w-full rounded-md  border border-gray-[#164789] shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50" />
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="self-end">
            <span>{'>'}</span>
          </div>
          <div>
            <label className="block text-[#164789] text-sm">Дуусах огноо</label>
            <ReactDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="yyyy-MM-dd"
              locale={mn}
              customInput={
                <ExampleCustomInput className=" px-2 text-sm mt-1 block w-full rounded-md  border border-gray-[#164789] shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50" />
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data?.getTransactions ?? []}
        customStyles={customStyles}
        highlightOnHover
        progressPending={loading}
        noDataComponent={
          <div className="grid place-items-center	">
            <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
            <span className="text-gray-500 text-sm ">Хоосон байна</span>
          </div>
        }
        pagination
        responsive
        progressComponent={
          <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
        }
      />
    </div>
  );
};

export default Index;
