import * as yup from 'yup';
import { PATTERN_EMAIL } from 'constants/pattern';

export const loginSchema = yup.object().shape({
  email: yup.string().required('И мэйл оруулна уу').matches(PATTERN_EMAIL, 'И мэйл алдаатай байна'),

  password: yup
    .string()
    .min(6, 'Нууц үг 6 оронтой байх шаардлагатай')
    .required('Нууц үгээ оруулна уу'),
});
