import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

interface NavigationMenuProps {
  navigations: any[];
}

const NavigationMenu = ({ navigations }: NavigationMenuProps) => {
  const history = useNavigate();
  const location = useLocation();

  const renderNavigationItems = (navItems: any) => {
    return navItems.map((nav: any, index: any) => (
      <div key={index}>
        <div
          onClick={() => history(isEmpty(nav.child) ? nav.path : nav.child[0].path)}
          className={`menu-item flex justify-between text-white hover:bg-[#6987af] ${
            location.pathname === nav.path ? 'bg-[#6987af]' : ''
          }`}
        >
          <li className="flex items-center gap-2">
            <div className="text-white">{nav.logo}</div>
            {nav.name}
          </li>
          <span className="text-xs font-semibold text-white">{nav.count}</span>
        </div>

        {nav.child && nav.child.length > 0 && (
          <div className="ml-8 mt-1">
            {nav.child.map((child: any) => (
              <div
                onClick={() => history(child.path)}
                className={`menu-item flex justify-between text-white hover:bg-gray-500 ${
                  location.pathname === child.path ? 'bg-gray-500' : ''
                }`}
              >
                <li className="flex items-center gap-2">
                  <div
                    className={`${
                      location.pathname === child.path ? 'text-white' : 'text-gray-600'
                    }`}
                  >
                    {child.logo}
                  </div>
                  {child.name}
                </li>
                <span className="text-xs font-semibold text-white">{child.count}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return <div>{renderNavigationItems(navigations)}</div>;
};

export default NavigationMenu;
