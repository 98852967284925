import { isEmpty } from 'lodash';
import { FieldValues } from 'react-hook-form';
import { ITransaction } from 'types';
import * as yup from 'yup';

export const technicalConditionSchema = (step: number) =>
  yup.object().shape({
    files: yup.array().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 1
        ? yup
            .array()
            .min(1, 'Дор хаяж нэг файл шаардлагатай')
            .of(
              yup.object().shape({
                name: yup.string().required('File name is required'),
              }),
            )
            .test('is-unique', 'файлын нэр давхцахгүй', function (value) {
              if (Array.isArray(value)) {
                const fileNames = value.map((file) => file.name);
                return new Set(fileNames).size === fileNames.length;
              }
              return true;
            })
        : yup.array().notRequired();
    }),

    paymentType: yup.string().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 3
        ? yup.string().required('Төлбөрийн нөхцөл сонгоно уу.')
        : yup.string().notRequired();
    }),
  });

export const validateButton = (step: number, watch: any, transaction: ITransaction) => {
  const { files, paymentType } = watch();
  switch (step) {
    case 1:
      return isEmpty(files);
    case 3:
      return isEmpty(paymentType);
    case 4:
      return transaction.state !== 'PAID';
    default:
      return false;
  }
};
