import { useQuery } from '@apollo/client';
import Chart from 'components/Chart';
import { GET_TRANSACTION_SUMMARIES } from 'graphql/query';
import React from 'react';

const Index = () => {
  const { data } = useQuery(GET_TRANSACTION_SUMMARIES);

  const cardsData = [
    { title: 'Нийт бүртгэлтэй захиалагч ', value: '2,104', percentage: '20%', isPositive: true },
    { title: 'Нийт бүртгэлтэй гүйцэтгэгч', value: '37', percentage: '15', isPositive: true },
    { title: 'Average Contract', value: '$1,553', percentage: '7.3%', isPositive: true },
    { title: 'Growth Rate', value: '8.29%', percentage: '1.3%', isPositive: true },
  ];

  return (
    <div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 p-4">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 text-center border border-gray-200"
          >
            <h3 className="text-gray-500 font-medium">{card.title}</h3>
            <p className="text-4xl font-bold text-gray-800">{card.value}</p>
            <p
              className={`text-green-500 font-medium mt-2 ${
                card.isPositive ? 'text-green-500' : 'text-red-500'
              }`}
            >
              ↑ {card.percentage}
            </p>
            <p className="text-gray-400 text-sm mt-1">өмнөх 30 хоногтой харьцуулахад</p>
          </div>
        ))}
      </div>
      <div className="ml-4 mb-2">
        <span className="font-semibold text-[#164789]">Сүүлийн 30 хонгийн гүйлгээ</span>
      </div>
      <Chart data={data?.getTransactionSummaries ?? []} />
    </div>
  );
};

export default Index;
