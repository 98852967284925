import logo from '../../../assets/logo.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import {
  BrowserRouter as _Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { TbCalendarBolt } from 'react-icons/tb';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { CiViewTable } from 'react-icons/ci';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { dashboardRoutes } from './routes';
import { useContext } from 'react';
import { AuthContext } from 'providers';
import { useQuery } from '@apollo/client';
import { GET_ADMIN_OFFERS, GET_ORDERS } from 'graphql/query';
import { TbReport } from 'react-icons/tb';
import NavigationMenu from 'components/NavigationItem';
import { LuLayoutDashboard } from 'react-icons/lu';
import ErrorPage from 'pages/error/notFound';

const Index = () => {
  let location = useLocation();
  const history = useNavigate();
  const { data } = useQuery(GET_ORDERS);
  const { signOut } = useContext(AuthContext);
  const { data: adminOffers } = useQuery(GET_ADMIN_OFFERS);

  const navigations = [
    {
      name: 'Хяналтын самбар',
      count: null,
      path: '/',
      logo: <LuLayoutDashboard className="h-6 w-6" />,
      child: [],
    },
    {
      name: 'Захиалга ',
      count: data && data.getOrders?.length,
      path: '/order',
      logo: <CiViewTable className="h-6 w-6" />,
      child: [],
    },
    {
      name: 'Ажил ',
      path: '/work',
      count: adminOffers && adminOffers.getAdminOffers?.length,
      logo: <TbCalendarBolt className="h-6 w-6" />,
    },
    {
      name: 'Гүйлгээ',
      path: '/transactions',
      count: null,
      logo: <TbReport className="h-6 w-6" />,
    },
  ];

  const onExit = async () => {
    await signOut();
    await history('/');
  };

  const navigation = (
    <nav className="menu bg-transparent p-2 rounded-md h-full justify-between ">
      <section className="menu-section h-full place-content-between">
        <ul className="menu-items ">
          <NavigationMenu navigations={navigations} />
        </ul>
        <ul className="menu-items rounded-lg mb-24 sm:mb-2 ">
          <li className="menu-item  text-white hover:bg-[#6987af] " onClick={() => onExit()}>
            <RiLogoutBoxLine className="h-6 w-6 text-white " />
            Гарах
          </li>
        </ul>
      </section>
    </nav>
  );

  return (
    <div className="grid min-h-screen w-full grid-cols-1 lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-[#164789] lg:block ">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-[60px] items-center border-b border-[#7aa6e1] px-6">
            <div className="flex items-center gap-2 font-semibold">
              <img src={logo} alt="logo" className="h-10 w-10" />
              <span className="text-white font-bold text-base ">EPOWER</span>
            </div>
          </div>
          <div className="flex-1 overflow-auto py-2">{navigation}</div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-4 border-b bg-gray-100/40 px-4 lg:h-[60px] lg:px-6 ">
          <input type="checkbox" id="drawer-left" className="drawer-toggle" />
          <label htmlFor="drawer-left" className="lg:hidden  p-1 rounded-lg">
            <RxHamburgerMenu className="w-6 h-6" />
          </label>
          <label className="overlay" htmlFor="drawer-left"></label>
          <div className="drawer">
            <div className="drawer-content bg-gray-800 pt-10 flex flex-col h-full">
              <label htmlFor="drawer-left" className="h-full">
                <div className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</div>
                <div className="w-full mt-4 bg-gray-800 h-full">{navigation}</div>
              </label>
            </div>
          </div>

          <div className="flex-1">
            <div className="flex items-center gap-2">
              <h1 className="font-semibold text-lg hidden md:flex text-[#164789]">
                {navigations.find((nav) => nav.path === location.pathname)?.logo}
              </h1>
              <h1 className="font-semibold text-lg text-[#164789]">
                {navigations.find((nav) => nav.path === location.pathname)?.name}
              </h1>
            </div>
          </div>
          <div className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white">
            <div className="p-1 rounded-full bg-gray-200">
              <IoIosNotificationsOutline className="w-6 h-6 text-gray-600" />
            </div>

            {/* <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 ">
              {data &&
                data.getOrders?.filter((order: IOrder) => order.state === TaskState.PENDING).length}
            </div> */}
          </div>
        </header>
        <main className=" p-4 md:p-6 grid gap-4 md:gap-8">
          <Routes>
            {dashboardRoutes.map((route, index) => (
              <Route
                errorElement={<ErrorPage />}
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Index;
