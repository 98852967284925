import { gql } from '@apollo/client';

export const ON_PAID_TRANSACTION = gql`
  subscription onPaidTransaction($user: ID!) {
    onPaidTransaction(user: $user) {
      id
      event
      user
      transaction {
        id
        token
        invoice
        image
        description
        entry
        amount
        state
        purpose
        object
        links {
          name
          logo
          description
          link
        }
      }
    }
  }
`;

export const ON_UPDATED_ORDER = gql`
  subscription onUpdatedOrder($user: ID) {
    onUpdatedOrder(user: $user) {
      id
      event
      user
      order {
        id
        createdAt
        date
        number
        state
        tasks {
          id
          type
          state
          documents {
            id
            name
            path
          }
        }
        documents {
          id
          name
          path
        }
      }
    }
  }
`;
