import { gql } from '@apollo/client';

export const GET_TRANSACTIONS = gql`
  query getTransactions($startDate: AWSDate, $endDate: AWSDate) {
    getTransactions(startDate: $startDate, endDate: $endDate) {
      id
      token
      invoice
      image
      description
      entry
      amount
      payedAt
      state
      purpose
      object
      user {
        id
        firstName
        phone
      }
      partner {
        id
        name
        phone
      }
      links {
        name
        logo
        description
        link
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query getTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      payedAt
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;

export const GET_ORDER_TRANSACTION = gql`
  query getOrderTransaction($id: ID!) {
    getOrderTransaction(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      payedAt
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;

export const GET_TRANSACTION_SUMMARIES = gql`
  query GetTransactionSummaries {
    getTransactionSummaries {
      date
      count
      amount
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData {
    getTotalUsers
    getTotalPartners
  }
`;
