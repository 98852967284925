import { gql } from '@apollo/client';
export const GET_ORDERS = gql`
  {
    getOrders {
      id
      createdAt
      date
      number
      state
      user {
        id
        phone
        firstName
      }
      isRead
      tasks {
        id
        type
        state
        detailedPicture
        taskConfig
        documents {
          id
          name
          path
          input
        }
      }
      documents {
        id
        name
        path
        input
      }
    }
  }
`;
