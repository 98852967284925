export const TECHNICAL_CONDITIONS = 'technical-conditions';

export const TaskState = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
  POSTED: 'POSTED',
  CANCELLED: 'CANCELLED',
};
