import { abilities, purposeWorks, workTypes } from 'mocks';

export function generateRandomString() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${result}.pdf`;
}

export function extractUUID(url: string) {
  const uuidRegex = /\/gerel\.tog\.mn\/tnshow\/([^/]+)/i;
  const match = url.match(uuidRegex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // UUID not found in URL
  }
}

export const getPurposeWork = (config: string, target: string) => {
  try {
    const items = JSON.parse(config);
    return items.find((item: string) => item.startsWith(target));
  } catch (error) {
    console.error('Error parsing config:', error);
    return null;
  }
};

export const extractName = (value: string): string | undefined => {
  const allValues = [...purposeWorks, ...workTypes, ...abilities];
  const foundItem = allValues.find((item) => item.value === value);
  return foundItem ? foundItem.name : value;
};

export const downloadFile = async (path: string) => {
  try {
    const response = await fetch(path, { mode: 'cors' });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', path.split('/').pop() || 'file'); // Extract filename from path
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Clean up
    alert('Download started');
  } catch (error) {
    console.error('Error downloading file:', error);
    alert('Failed to download file');
  }
};
