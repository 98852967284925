import * as yup from 'yup';
import { PATTERN_PHONE } from 'constants/pattern';
import { FieldValues } from 'react-hook-form';

export const registerSchema = yup.object().shape({
  phone: yup
    .string()
    .required('Та утасны дугаар оруулна уу')
    .matches(PATTERN_PHONE, 'Утасны дугаар алдаатай байна'),
  pin: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) >= 2
      ? yup.number().required('4 оронтой кодыг оруулна уу')
      : yup.string().notRequired();
  }),
  password: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) >= 3
      ? yup.string().min(6, 'Нууц үг 6 оронтой байх шаардлагатай').required('Нууц үгээ оруулна уу')
      : yup.string().notRequired();
  }),
  passwordRepeat: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    let password = options.parent.password;

    return Number(step) >= 3
      ? password && password.length > 0
        ? yup
            .string()
            .oneOf([yup.ref('password')], 'Нууц үгүүд таарахгүй байна')
            .required('Нууц үгээ давтан оруулна уу')
        : yup.string().notRequired()
      : yup.string().notRequired();
  }),
});
