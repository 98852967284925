import React from 'react';
import SuspenseLoad from '../components/SuspenseLoad';
import RoutesWrapper from 'components/RoutesWrapper/RoutesWrapper';

const Index: React.FC = () => {
  return (
    <SuspenseLoad>
      <RoutesWrapper />
    </SuspenseLoad>
  );
};

export default Index;
