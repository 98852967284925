import { gql } from '@apollo/client';

export const GET_ADMIN_OFFERS = gql`
  {
    getAdminOffers {
      id
      createdAt
      price
      state
      partnerId
      types
      chargeAmount
      partner {
        id
        name
        phone
      }
      task {
        id
        type
        state
        isRead
        completedAt
        detailedPicture
        orderId
        taskConfig
        workTypes
        documents {
          id
          name
          path
          input
        }
      }
    }
  }
`;
