import ControlledInput from 'components/ControlledForms/ControlledInput';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import { loginSchema } from 'components/resolvers';
import { useMutation } from '@apollo/client';
import { LOGIN_BY_ADMIN } from 'graphql/mutation';
import { useContext, useEffect } from 'react';
import { AuthContext, useNotifications } from 'providers';
import { useNavigate } from 'react-router-dom';
import { PAGE_INDEX } from 'constants/pages';
import { useCallStore } from 'context';

const Index = () => {
  let history = useNavigate();
  const { authenticate } = useContext(AuthContext);
  const { addNotification } = useNotifications();

  const { control, handleSubmit, setError } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(loginSchema) as any,
    defaultValues: {
      step: 1,
    },
  });

  const [loginByAdmin, { loading }] = useMutation(LOGIN_BY_ADMIN, {
    onCompleted: (data) => {
      authenticate(data.loginByAdmin.token, () => {
        addNotification('Амжилттай нэвтэрлээ!', 'success');
        history(PAGE_INDEX);
      });
    },
    onError: (error) => {
      setError('email', { type: 'server', message: error.message });
    },
  });

  const onSubmit = (data: FieldValues) => {
    loginByAdmin({ variables: { email: data.email, password: data.password } });
  };

  return (
    <div className="w-full grid justify-items-center h-screen items-center ">
      <div className="grid max-w-xl mt-4  md:px-6 lg:px-8">
        <div className="w-full px-4 mb-6 ">
          <div className=" grid w-full text-center mb-4">
            <span className="text-xl font-semibold mb-4 text-[#164789]">Админ эрхээр нэвтрэх</span>
            <span className="text-sm text-gray-500 ">
              Ажлын захиалагч болон ажил гүйцэтгэгчийн цахим бирж
            </span>
          </div>
          <ControlledInput
            control={control}
            placeholder="И мэйл хаягаа оруулна уу"
            name="email"
            type="email"
          />
          <ControlledInput
            control={control}
            placeholder="Нууц оруулна уу"
            name="password"
            type="password"
          />
          <Button text="Үргэлжлүүлэх" loading={loading} onClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
export default Index;
