import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

export const OrderProcessSchema = yup.object().shape({
  workingDrawings: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    let type = options.parent.type;

    return Number(step) === 1
      ? type === 1
        ? yup.string().required('Та нарийвчилсан ажлын зураг оруулна уу')
        : yup.string().notRequired()
      : yup.string().notRequired();
  }),
  files: yup.array().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) === 2
      ? yup
          .array()
          .min(1, 'Дор хаяж нэг файл шаардлагатай')
          .of(
            yup.object().shape({
              name: yup.string().required('File name is required'),
            }),
          )
      : yup.array().notRequired();
  }),
});
