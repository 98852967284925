import React from 'react';
import './App.css';
import Routes from './routes';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { LANG_RESOURCES } from 'config/lang';
import { ApolloProvider } from '@apollo/client';
import client from 'providers/client';
import { AuthProvider, NotificationProvider } from 'providers';
import SubscriptionProvider from 'providers/subscription';

function App() {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'mn',
    resources: LANG_RESOURCES,
  });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <NotificationProvider>
          <SubscriptionProvider>
            <I18nextProvider i18n={i18next}>
              <React.Suspense
                fallback={
                  <div className="flex items-center justify-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
                  </div>
                }
              >
                <Routes />
              </React.Suspense>
            </I18nextProvider>
          </SubscriptionProvider>
        </NotificationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
