export const QPAY_BANK_TYPE = {
  MBANK: 'M bank',
  KHAAN_BANK: 'Khan bank',
  SOCIAL_PAY: 'Social Pay',
  BOGD_BANK: 'Bogd bank',
  CAPITRON_BANK: 'Capitron bank',
  CHINGIG_KHAAN_BANK: 'Chinggis khaan bank',
  MOST_MONEY: 'Most money',
  NATIONAL_INVESTMENT_BANK: 'National investment bank',
  STATE_BANK: 'State bank',
  TRADE_AND_DEVELOPMENT_BANK: 'Trade and Development bank',
  KHAS_BANK: 'Xac bank',
  MONPAY: 'Monpay',
  ARIG_BANK: 'Arig bank',
  ARD_APP: 'Ard App',
  TRANS_BANK: 'Trans bank',
};
